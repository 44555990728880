import { IUserSetting, IInsuranceSetting } from '@src/interfaces/IBundle';
import { appAxios } from './AppAxios';
import { AxiosError, AxiosResponse } from 'axios';

export const BalanceApiServices = {
  getAuStockBalance: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/top/austocks');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getAuBalance: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/top/swallow');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getBalanceDetail: async(id: string, datetime: string) => {
    try {
      const params = { params: { time: datetime } };
      const { data }: AxiosResponse = await appAxios.get('/asset/detail/' + id, params);
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getAuInsurance: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/lpsonpo/contracts');
      return data;
    } catch (error) {
      const axiosErr = error as AxiosError;
      axiosErr.stack = new Error().stack;
      throw axiosErr;
    }
  },

  getTransactionsChart: async(account_ids: string, from: string, to: string, dataSource: number, mainAccountID: string) => {
    try {
      const params = { 'account_ids[]': account_ids, from, to, data_source: dataSource, main_account_id: mainAccountID };
      const { data }: AxiosResponse = await appAxios.get('/asset/transactions/chart', { params });
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  updateBundleSettings: async(
    showFinancialPackages: boolean
  ) => {
    try {
      await appAxios.post('/asset/settings/saves', { message_settings: { show_financial_packages: showFinancialPackages } });
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
  getBundleInfo: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/asset/settings/info');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  updateInsuranceSetting: async(
    userSettings: IInsuranceSetting
  ) => {
    try {
      const { data } = await appAxios.post(
        '/asset/settings/saves',
        { user_settings: userSettings }
      );
      return data;
    } catch (error) {
      (error as AxiosError).stack = new Error().stack;
      throw error;
    }
  },

  updateUserSetting: async(
    user_settings: IUserSetting
  ) => {
    try {
      const { data } = await appAxios.post(
        '/asset/settings/saves',
        { user_settings }
      );
      return data;
    } catch (error) {
      (error as AxiosError).stack = new Error().stack;
      throw error;
    }
  }
};
