import { all, takeLatest, put, call } from 'redux-saga/effects';
import Router from 'next/router';
import { actionTypes } from '@src/actions/loginLinkActions';
import errorHandler from '@src/utils/errorHandler';
import { setLoading } from '@src/actions/commonActions';
import { pathConstant } from '@src/utils/appContanst';
import { AccountApiServices } from '@src/api/AccountApiServices';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function * linkAccountSuccessSaga(action: any) {
  const { params } = action;

  try {
    yield put(setLoading(true));
    const { status } = yield call(AccountApiServices.postDataVerifyKabucom, params);

    if (status === 200) {
      Router.push(pathConstant.assetPath);
    }
    yield put(setLoading(false));
  } catch (err) {
    errorHandler(err);
  }
}

function * watchLinkAccountSuccessSaga() {
  yield takeLatest(actionTypes.LINK_ACCOUNT_SUCCESS, linkAccountSuccessSaga);
}

export function * loginLinkSaga() {
  yield all([
    watchLinkAccountSuccessSaga()
  ]);
}
