import { setTracker } from '@src/utils/analytics';
import { IAssetInfor } from '@src/interfaces/IAssetInfor';
import {
  IAccountInfo,
  IAuInsurance,
  IBalanceState,
  IUserCondition
} from '@src/interfaces/IBalanceState';
import { getAuCreditType } from '@src/utils/functionUtils';
import { getExistInsurancesStatus } from '@src/utils/insuranceUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValueDimension = (linkedAccounts: number, prefixValue: string | any) => {
  const value = linkedAccounts > 0 ? 'user' : 'nouser';
  return prefixValue + '_' + value;
};

const getAupayCardDimension = (
  linkedAccounts: number,
  prefixValue: string,
  credit: IAccountInfo
) => {
  let value = 'nouser';
  if (linkedAccounts > 0) {
    // credit only have one account, we should only check the first item of account
    const creditType = credit.accounts.length
      ? getAuCreditType(credit.accounts[0].crd_sbt || '')
      : '';
    value = creditType === 'typeTwo' ? 'user_new' : 'user';
  }
  return prefixValue + '_' + value;
};

const getIndexStringDimension = (name: string) => {
  switch (name) {
    case 'aa':
      return 'dimension1';
    case 'bank':
      return 'dimension2';
    case 'card':
      return 'dimension3';
    case 'emoney':
      return 'dimension4';
    case 'point':
      return 'dimension5';
    case 'ec':
      return 'dimension6';
    case 'stock':
      return 'dimension7';
    case 'au_pay':
      return 'dimension8';
    case 'au_ponta':
      return 'dimension9';
    case 'au_ponta_market':
      return 'dimension10';
    case 'au_point_unyo':
      return 'dimension11';
    case 'au_pay_card':
      return 'dimension12';
    case 'au_jbank':
      return 'dimension13';
    case 'au_market':
      return 'dimension14';
    case 'au_stock':
      return 'dimension15';
    case 'au_kantan':
      return 'dimension16';
    case 'financialBundlePlanMember':
      return 'dimension17';
    case 'jibunBankAccountHoldSts':
      return 'dimension18';
    case 'financeDisPaysetSts':
      return 'dimension19';
    case 'payCardHoldSts':
      return 'dimension20';
    case 'au_life':
      return 'au_life';
    case 'au_nonlife':
      return 'au_nonlife';
    case 'au_nonlifecar':
      return 'au_nonlifecar';
    default:
      return 'dimension1';
  }
};

export const trackAnalyticsAAUser = (assetInfor: IAssetInfor, balance: IBalanceState) => {
  const { aa_status, system_id: userId } = assetInfor;
  const { credit } = balance;
  const mapDimensionKey: {[keyVal: string]: string} = {
    aa: 'aa',
    bank: 'bank',
    card: 'card',
    emoney: 'money',
    point: 'point',
    stock: 'securities',
    ec: 'EC',
    au_pay: 'auPAY',
    au_ponta: 'Ponta',
    au_ponta_market: 'Pontapay',
    au_point_unyo: 'punyou',
    au_pay_card: 'auPAYcard',
    au_jbank: 'jigin',
    au_market: 'payma',
    au_stock: 'kabukomu',
    au_kantan: 'kantan'
  };

  setTracker({ userId });

  Object.keys(aa_status).map((key: string) => {
    const indexString = getIndexStringDimension(key);
    let value = '';
    if (key === 'au_pay_card') {
      value = getAupayCardDimension(
        aa_status[key as keyof typeof aa_status],
        mapDimensionKey[key] || key,
        credit
      );
    } else {
      value = getValueDimension(
        aa_status[key as keyof typeof aa_status],
        mapDimensionKey[key] || key
      );
    }
    setTracker({ [indexString]: value });
    dataLayer.push({ [key]: value });
  });
};

const setDimension = (dimensionKey: string, value: string) => {
  setTracker({ [dimensionKey]: value });
  dataLayer.push({ [dimensionKey]: value });
};

export const trackAnalyticsBundle = (setUserCondition?: IUserCondition) => {
  const dimensionMappings: { [key: string]: { [key: number]: string; default: string } } = {
    financialBundlePlanMember: {
      1: 'fb_user',
      0: 'fb_nouser',
      default: 'unknown'
    },
    payCardHoldSts: {
      1: 'au_PAYcard_user',
      0: 'au_PAYcard_nouser',
      default: 'unknown'
    },
    jibunBankAccountHoldSts: {
      1: 'jigin_auID_user',
      0: 'jigin_auID_nouser',
      default: 'unknown'
    },
    financeDisPaysetSts: {
      1: 'jigin',
      2: 'au_PAYcard',
      0: 'other',
      default: 'unknown'
    }
  };

  if (setUserCondition) {
    Object.entries(setUserCondition).forEach(([key, value]) => {
      const dimensionKey = getIndexStringDimension(key);
      if (dimensionMappings[key] && dimensionMappings[key][value]) {
        setDimension(dimensionKey, dimensionMappings[key][value]);
      } else {
        setDimension(dimensionKey, dimensionMappings[key].default || 'unknown');
      }
    });
  }
};

export const trackAnalyticsInsurance = (insurance: IAuInsurance) => {
  const {
    isExistLifeInsurance,
    isExistNonlifeInsurance,
    isExistCarInsurance
  } = getExistInsurancesStatus(insurance);

  const KEY_MAPPING: Record<string, string> = {
    0: 'au_life',
    1: 'au_nonlife',
    2: 'au_nonlifecar'
  };

  const valueArray = [
    isExistLifeInsurance ? 'au_life_user' : 'au_life_nouser',
    isExistNonlifeInsurance ? 'au_nonlife_user' : 'au_nonlife_nouser',
    isExistCarInsurance ? 'au_nonlifecar_user' : 'au_nonlifecar_nouser'
  ];

  valueArray.forEach((item, index) => {
    const dimensionKey = getIndexStringDimension(KEY_MAPPING[index]);
    setDimension(dimensionKey, item);
  });
};
