import { assetTopAnalyticsEvents } from '@src/analyticsEvents/assetTopAnalyticsEvents';
import { IBalanceAccountState, IBalanceState, IUserCondition } from '@src/interfaces/IBalanceState';
import { IBundleResponse } from '@src/interfaces/IBundle';
import { IAnalyticKarteEventTopArgs } from '@src/interfaces/IGoogleAnalytic';
import {
  AccountType,
  CAMPAIGN_TEXT_KARTE_TRANSFER,
  TRACKING_KARTE_ACC_TYPE,
  MAINTENANCE_STATUS,
  FINANCIAL_BUNDLE
} from '@src/utils/appContanst';
import { detectSysDatetime } from '@src/utils/dateTime';
import { formatMMDD, formatMoney, formatYYMMDD } from '@src/utils/format';
import {
  getAccountType,
  getAuCreditType,
  getAuObj,
  getBundleMsg
} from '@src/utils/functionUtils';
import { initialPointInfo, initialUserCondition } from '@src/utils/initialData';
import { getExistInsurancesStatus } from '@src/utils/insuranceUtils';
import _filter from 'lodash.filter';

export const getKarteEventAuAcc = (
  karteAcc: IBalanceAccountState,
  auAccShowcampaignText: string,
  maintenance_status: number,
  setUserCondition?: IUserCondition,
  bundleSettings?: IBundleResponse
) => {
  const {
    id,
    status,
    kantan_info,
    expire_at,
    point_info,
    data_source,
    create_date,
    is_error,
    last_succeeded_at,
    crd_sbt = ''
  } = karteAcc;
  const currentAccType = getAccountType(data_source);
  const isAuStock = AccountType.au_stock === currentAccType;
  const isAuPonta = currentAccType === AccountType.au_ponta;
  let theme = 'normal';
  let campaignText = '';
  if (is_error || !id) {
    const auObj = getAuObj(id, data_source, status, point_info, kantan_info);
    theme = auObj.theme;
    campaignText = auObj.campaignText;
  }

  const isLinked = id ? 1 : 0;
  const signInDate = create_date ? new Date(formatYYMMDD(create_date)) : '';
  const lastSucceededAt = last_succeeded_at ? new Date(formatYYMMDD(last_succeeded_at)) : '';
  const { toBeInvalidDate = '', toBeInvalidDatePayma = '', toBeInvalidPointTotal } = point_info || initialPointInfo;
  const validDate = toBeInvalidDate || toBeInvalidDatePayma || expire_at;
  const { isExpiredLessAMonth } = detectSysDatetime(validDate);
  const campaignTexts = `${formatMMDD(toBeInvalidDate)}に失効する${formatMoney(toBeInvalidPointTotal)}Pを、ポイント運用しませんか？`;
  const auPontaCampainText = isAuPonta && isExpiredLessAMonth && (theme === 'normal') ? campaignTexts : '';

  // logic for credit
  const creditMessageMap: Record<string, number> = {
    typeOne: 1,
    typeTwo: 2,
    notOwn: 0
  };
  const creditType = getAuCreditType(crd_sbt);
  const creditMessage = id ? creditMessageMap[creditType] : 0;

  // show msg incase au_stock have not linked yet
  const auStockCampainText = 'お持ちのPontaポイントで手軽に投資信託が買える';
  const showAllCampaignText = auAccShowcampaignText === 'au_stock' ? auStockCampainText : campaignText;
  const enableCampaignText = auAccShowcampaignText === 'ALL'
    ? showAllCampaignText
    : showAllCampaignText && auAccShowcampaignText === currentAccType;
  const isAccNotMaintance = MAINTENANCE_STATUS.NO_MAINTENANCE === maintenance_status;

  // check if au_stock is open account and not in maintance, then show campaign msg
  const isAuStockHasMsg = isAccNotMaintance && !id && isAuStock;
  const textCampaignKarte = isAuStockHasMsg ? auStockCampainText : campaignText;
  const bundleMsg =
    bundleSettings &&
    getBundleMsg(
      setUserCondition || initialUserCondition,
      bundleSettings,
      data_source,
      'unlinked'
    );
  const msgForKarte = {
    campaignMsg: enableCampaignText ? textCampaignKarte : ''
  };
  const existBundleMsg = bundleMsg && bundleMsg.campaignMsg;
  const existMsgForKarte = msgForKarte && msgForKarte.campaignMsg;
  const isShowRandomMsgWithBundle = auAccShowcampaignText && enableCampaignText;
  const campaignMsgForBundle = bundleMsg && bundleMsg.campaignMsg && isShowRandomMsgWithBundle ? bundleMsg.campaignMsg : '';
  const anotherCampaignMsg = existMsgForKarte ? CAMPAIGN_TEXT_KARTE_TRANSFER[currentAccType] : '';
  const campaignMessage = existBundleMsg
    ? campaignMsgForBundle
    : anotherCampaignMsg;

  let karteEvent: IAnalyticKarteEventTopArgs = {};

  switch (currentAccType) {
    case AccountType.au_prepaid: {
      karteEvent = {
        aupay_touroku_umu: isLinked,
        aupay_touroku_nichiji_date: signInDate
      };
      break;
    }
    case AccountType.au_ponta: {
      karteEvent = {
        ponta_event_page_mei: window.location.href,
        ponta_tuouroku_umu: isLinked,
        ponta_touroku_nichiji_date: signInDate,
        ponta_text_sokyu: auPontaCampainText
      };
      break;
    }
    case AccountType.au_kantan_kessai: {
      karteEvent = {
        aukantankessai_touroku_umu: isLinked,
        aukantankessai_touroku_nichiji_date: signInDate
      };
      break;
    }
    case AccountType.au_stock: {
      karteEvent = {
        aukabukomu_shoken_2404_touroku_umu: isLinked,
        aukabukomu_shoken_2404_touroku_nichiji_date: lastSucceededAt,
        aukabukomu_shoken_2404_text_sokyu: campaignMessage
      };
      break;
    }
    case AccountType.au_jbank: {
      karteEvent = {
        aujibun_ginkou_touroku_umu: isLinked,
        aujibun_ginkou_touroku_nichiji_date: signInDate,
        aujibun_ginkou_touroku_text_sokyu: campaignMessage
      };
      break;
    }
    // au Pay Card
    case AccountType.au_credit: {
      karteEvent = {
        aupaycard_touroku_umu: creditMessage,
        aupaycard_touroku_nichiji_date: signInDate,
        aupaycard_text_sokyu: campaignMessage
      };
      break;
    }
    case AccountType.au_market: {
      karteEvent = {
        aupay_market_touroku_umu: isLinked,
        aupay_market_touroku_nichiji_date: signInDate,
        aupay_market_text_sokyu: campaignMessage
      };
      break;
    }
  }

  return karteEvent;
};

export function handleSendEventToKarteForAuAcc(
  balanceState: IBalanceState,
  auAccShowcampaignText: string,
  setUserCondition?: IUserCondition,
  bundleSettings?: IBundleResponse
) {
  const {
    point,
    bank,
    credit,
    e_money,
    ec,
    stock,
    kantan,
    maintenance_status,
    insurance
  } = balanceState;
  const allAccount = [
    ...point.accounts,
    ...bank.accounts,
    ...credit.accounts,
    ...e_money.accounts,
    ...ec.accounts,
    ...stock.accounts,
    ...kantan.accounts
  ];
  const pointAcc = point.accounts;
  const kartePointAccount = _filter(pointAcc,
    (account: IBalanceAccountState) => (AccountType.au_ponta === (getAccountType(account.data_source))));
  const karteAccounts = _filter(allAccount,
    (account: IBalanceAccountState) => TRACKING_KARTE_ACC_TYPE.includes(getAccountType(account.data_source)));
  const karteEventAuPonta = kartePointAccount.length &&
    getKarteEventAuAcc(
      kartePointAccount[0],
      auAccShowcampaignText,
      maintenance_status,
      setUserCondition,
      bundleSettings
    );
  let karteEventAuAcc: IAnalyticKarteEventTopArgs = { aupay_event_page_mei: window.location.href };
  const karteEventBundle = getKarteEventBundle(setUserCondition);

  karteAccounts.forEach((account: IBalanceAccountState) => {
    const karteEvent = getKarteEventAuAcc(
      account,
      auAccShowcampaignText,
      maintenance_status,
      setUserCondition,
      bundleSettings
    );

    karteEventAuAcc = {
      ...karteEventAuAcc,
      ...karteEvent
    };
  });

  const {
    isExistLifeInsurance,
    isExistNonlifeInsurance,
    isExistCarInsurance
  } = getExistInsurancesStatus(insurance);

  const insuranceKarteEvent: IAnalyticKarteEventTopArgs = {
    au_life_insurance_umu: isExistLifeInsurance ? 1 : 0,
    au_nonlife_insurance_car_umu: isExistCarInsurance ? 1 : 0,
    au_nonlife_insurance_umu: isExistNonlifeInsurance ? 1 : 0
  };
  karteEventAuAcc = {
    ...karteEventAuAcc,
    ...insuranceKarteEvent
  };

  assetTopAnalyticsEvents.eventClickKarteForTop('top_ponta', karteEventAuPonta);
  assetTopAnalyticsEvents.eventClickKarteForTop('top_au', karteEventAuAcc);
  assetTopAnalyticsEvents.eventClickKarteForTop('top_fb', karteEventBundle);
}

export function handleKarteEventForInsurance() {
  const karteEventAuAcc: IAnalyticKarteEventTopArgs = {
    au_life_insurance_umu: 1
  };

  assetTopAnalyticsEvents.eventClickKarteForTop('top_au', karteEventAuAcc);
}

export function getKarteEventBundle(setUserCondition?: IUserCondition): IAnalyticKarteEventTopArgs {
  const {
    financialBundlePlanMember = -1,
    payCardHoldSts = -1,
    jibunBankAccountHoldSts = -1,
    financeDisPaysetSts = -1
  } = setUserCondition || {};

  const karteEventBundle: IAnalyticKarteEventTopArgs = {
    fb_event_page_mei: window.location.href,
    fb_umu: getBundleValueForKarte(financialBundlePlanMember),
    aujibun_ginkou_auid_touroku_umu: getBundleValueForKarte(jibunBankAccountHoldSts),
    ryokin_setting: getBundleValueForKarte(financeDisPaysetSts),
    aupaycard_touroku_umu: getBundleValueForKarte(payCardHoldSts)
  };

  return karteEventBundle;
}

export const getBundleValueForKarte = (value: number) => {
  const valueObject = {
    [FINANCIAL_BUNDLE.NOT_APPLIED]: '0',
    [FINANCIAL_BUNDLE.APPLIED]: '1',
    [FINANCIAL_BUNDLE.APPLIED_CASE_2]: '2',
    [FINANCIAL_BUNDLE.APPLIED_CASE_3]: '3',
    [FINANCIAL_BUNDLE.APPLIED_CASE_4]: '4',
    [FINANCIAL_BUNDLE.UNKNOWN]: 'unknown'
  };

  return valueObject[value];
};
