import { IAuInsurance } from '@src/interfaces/IBalanceState';

export const getInsuranceStatus = (agreeStatus: string) => {
  switch (agreeStatus) {
    case '01': {
      return {
        status: '申込中',
        color: 'orange-text'
      };
    }
    case '02': {
      return {
        status: '加入中',
        color: 'orange-text'
      };
    }
    case '03': {
      return {
        status: '未加入',
        color: 'green-text'
      };
    }
    case '04': {
      return {
        status: '未加入',
        color: 'green-text'
      };
    }
  }
};

export const getExistInsurancesStatus = (insurance: IAuInsurance) => {
  const {
    bicycle,
    car,
    dailyAccident,
    domestic,
    golf,
    life,
    oversea,
    pet,
    traffic
  } = insurance;

  const isExistLifeInsurance = life.length;
  const isExistNonlifeInsurance =
    bicycle.length ||
    dailyAccident.length ||
    domestic.length ||
    golf.length ||
    oversea.length ||
    pet.length ||
    traffic.length;
  const isExistCarInsurance = car.length;

  return {
    isExistLifeInsurance,
    isExistNonlifeInsurance,
    isExistCarInsurance
  };
};
