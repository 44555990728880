import _filter from 'lodash.filter';
import _clone from 'lodash.clone';
import { actionTypes } from '@src/actions/balanceActions';
import {
  IBalanceState,
  IAccountInfo,
  IAccount,
  IAuInsuranceResponseItem,
  IAuInsuranceResponse,
  IAuInsurance
} from '@src/interfaces/IBalanceState';
import { getCurrentMonth, getAccountType, isStockAccount } from '@src/utils/functionUtils';
import { AccountType, MAINTENANCE_STATUS, TYPE_FETCH_CHART } from '@src/utils/appContanst';
import { ITransactionChartItem } from '@src/interfaces/ITransactionState';
import { initialBalanceAcc, initialPointInfo } from '@src/utils/initialData';

export const initialState: IBalanceState = {
  bank: { accounts: [] },
  credit: { accounts: [] },
  e_money: { accounts: [] },
  point: { accounts: [] },
  ec: { accounts: [] },
  stock: { accounts: [] },
  kantan: { accounts: [] },
  insurance: {
    bicycle: [],
    car: [],
    dailyAccident: [],
    domestic: [],
    golf: [],
    life: [],
    oversea: [],
    pet: [],
    traffic: [],
    code: ''
  },
  balance_account: { ...initialBalanceAcc },
  transactionsChart: {
    isLoading: false,
    values: []
  },
  maintenance_status: MAINTENANCE_STATUS.NO_MAINTENANCE,
  scrollLeftBefore: 0,
  auAccShowcampaignText: '',
  isAuLoading: true,
  isStockLoading: true,
  isInsuranceLoading: true,
  bundle: {
    message_settings: {
      show_financial_packages: true
    },
    admin_settings: {
      show_financial_packages: true
    }
  },
  insuranceSettings: {
    show_au_life_insurance: true,
    show_au_sonpo: true
  },
  userSettings: {
    showTotalBalance: undefined
  },
  financialBundlePlanMember: -1,
  payCardHoldSts: -1,
  jibunBankAccountHoldSts: -1,
  financeDisPaysetSts: -1
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function balanceReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_BALANCE_INFO_SUCCESS:
    {
      const filterBalance = removeIsHideAccounts(action.balance);
      const {
        maintenance_status,
        financialBundlePlanMember = -1,
        payCardHoldSts = -1,
        jibunBankAccountHoldSts = -1,
        financeDisPaysetSts = -1
      } = action.balance;
      return {
        ...state,
        ...filterBalance,
        maintenance_status,
        monthSelect: getCurrentMonth(),
        balance_account: { ...initialBalanceAcc },
        transactionsChart: initialState.transactionsChart,
        scrollLeftBefore: 0,
        financialBundlePlanMember,
        payCardHoldSts,
        jibunBankAccountHoldSts,
        financeDisPaysetSts
      };
    }
    case actionTypes.GET_BALANCE_DETAIL_SUCCESS:
    {
      const { balanceAccount, datetime } = action;
      const { activeChartData } = balanceAccount;
      const { transactionsChart: { values } } = state;
      const transactionsChartValues = getUpdatedTransactionsChartDetail(values, datetime, activeChartData);

      return {
        ...state,
        balance_account: action.balanceAccount,
        transactionsChart: {
          ...state.transactionsChart,
          values: transactionsChartValues
        }
      };
    }
    case actionTypes.UPDATE_MONTH_SELECT:
      return {
        ...state,
        monthSelect: action.monthSelect
      };
    case actionTypes.GET_TRANSACTIONS_CHART:
      return {
        ...state,
        transactionsChart: {
          ...state.transactionsChart,
          isLoading: true
        }
      };
    case actionTypes.GET_TRANSACTIONS_CHART_SUCCESS:
      return {
        ...state,
        transactionsChart: {
          isLoading: false,
          values: action.transactionsChart
        }
      };
    case actionTypes.FETCH_MORE_TRANSACTIONS_CHART:
      return {
        ...state,
        transactionsChart: {
          ...state.transactionsChart,
          isLoading: true
        }
      };
    case actionTypes.FETCH_MORE_TRANSACTIONS_CHART_SUCCESS: {
      const values = action.typeFetchChart === TYPE_FETCH_CHART.PREV
        ? [...action.transactionsChart, ...state.transactionsChart.values]
        : [...state.transactionsChart.values, ...action.transactionsChart];
      return {
        ...state,
        transactionsChart: {
          isLoading: false,
          values
        }
      };
    }
    case actionTypes.SAVE_SCROLL_LEFT_BEFORE: {
      return {
        ...state,
        scrollLeftBefore: action.scrollLeft
      };
    }
    case actionTypes.REMOVE_UNUSED_ACCOUNT_IN_STORE: {
      const newData = handleRemoveUnsedAccountInStore(state, action.id);

      return {
        ...state,
        ...newData
      };
    }
    case actionTypes.UPDATE_ACC_SHOW_CAMPAIGN_TEXT: {
      return {
        ...state,
        auAccShowcampaignText: action.accountType
      };
    }
    case actionTypes.SET_AU_LOADING: {
      return {
        ...state,
        isAuLoading: action.isLoading
      };
    }
    case actionTypes.SET_STOCK_LOADING: {
      return {
        ...state,
        isStockLoading: action.isLoading
      };
    }
    case actionTypes.SET_INSURANCE_LOADING: {
      return {
        ...state,
        isInsuranceLoading: action.isLoading
      };
    }
    case actionTypes.GET_AU_AND_STOCK_BANLANCE_INFO_SUCCESS: {
      const response = handleAuMfResponse(
        action.isAuResponse,
        action.balanceAccount,
        state
      );
      const filterBalance = removeIsHideAccounts(response as IBalanceState);
      const maintenance_status = handleAuMFMaintenance(action.balanceAccount, state);
      return {
        ...state,
        ...filterBalance,
        maintenance_status,
        monthSelect: getCurrentMonth(),
        balance_account: { ...initialBalanceAcc },
        transactionsChart: initialState.transactionsChart,
        scrollLeftBefore: 0
      };
    }
    case actionTypes.GET_AU_INSURANCE_SUCCESS: {
      const data: IAuInsuranceResponse = action.data;

      const refinedData: IAuInsurance = {
        bicycle: convertInsuranceData(data.bicycle),
        car: convertInsuranceData(data.car),
        dailyAccident: convertInsuranceData(data.dailyAccident),
        domestic: convertInsuranceData(data.domestic),
        golf: convertInsuranceData(data.golf),
        life: convertInsuranceData(data.life),
        oversea: convertInsuranceData(data.oversea),
        pet: convertInsuranceData(data.pet),
        traffic: convertInsuranceData(data.traffic),
        code: data.code
      };

      return {
        ...state,
        insurance: refinedData
      };
    }
    case actionTypes.GET_BUNDLE_INFO_SUCCESS: {
      const {
        admin_settings,
        message_settings,
        user_settings
      } = action.bundleInfo;

      const {
        show_au_life_insurance,
        show_au_sonpo,
        show_total_balance
      } = user_settings;

      return {
        ...state,
        bundle: { admin_settings, message_settings },
        insuranceSettings: { show_au_life_insurance, show_au_sonpo },
        userSettings: {
          showTotalBalance: show_total_balance
        }
      };
    }
    case actionTypes.UPDATE_BUNDLE_SETTINGS: {
      return {
        ...state,
        bundle: {
          ...state.bundle,
          message_settings: action.message_settings
        }
      };
    }
    case actionTypes.TOGGLE_TOTAL_BALANCE_SUCCESS: {
      return {
        ...state,
        userSettings: {
          showTotalBalance: action.showTotalBalance
        }
      };
    }
    default:
      return state;
  }
}

function handleAuMFMaintenance(response: IBalanceState, state: IBalanceState) {
  let maintenanceStatus = response.maintenance_status || state.maintenance_status;

  if (response.maintenance_status && state.maintenance_status) {
    maintenanceStatus = MAINTENANCE_STATUS.BOTH_MAINTENANCE;
  }
  return maintenanceStatus;
}

function handleAuMfResponse(isAuResponse: boolean, response: IBalanceState, state: IBalanceState) {
  const storeAccounts: IBalanceState = initialState;
  const arrStoreAccount = ['bank', 'credit', 'e_money', 'ec', 'point', 'stock', 'kantan'];

  arrStoreAccount.forEach((typeAcc) => {
    const stateObj = { ...state[typeAcc] };
    const responseObj = { ...response[typeAcc] };
    const newObj = { ...storeAccounts[typeAcc] };
    const stateItems = _filter(stateObj.accounts, (account: IAccount) => (isAuResponse ? isStockAccount(account.data_source) : !isStockAccount(account.data_source)));
    newObj.recommend = { ...responseObj.recommend };

    if (isAuResponse) {
      newObj.accounts = [...responseObj.accounts, ...stateItems];
    } else {
      newObj.accounts = [...stateItems, ...responseObj.accounts];
    }

    storeAccounts[typeAcc] = { ...newObj };
  });

  return storeAccounts;
}

function handleRemoveUnsedAccountInStore(data : {[key: string]: any}, id: string) {
  const newData = { ...data };

  ['bank', 'credit', 'e_money', 'point', 'ec', 'kantan'].forEach((key: string) => {
    const newObj = newData[key] || { accounts: [] };
    const newAccounts = _filter([...newObj.accounts], (item: IAccount) => {
      const { point_info = initialPointInfo } = item;

      return (item.id !== id && point_info.main_account_id !== id);
    });
    newData[key].accounts = newAccounts;
  });

  return newData;
}

function removeIsHideAccounts(balanceObj: IBalanceState) {
  const initAccInfo = { accounts: [] };
  const bank: IAccountInfo = _clone(balanceObj.bank || initAccInfo);
  const credit: IAccountInfo = _clone(balanceObj.credit || initAccInfo);
  const e_money: IAccountInfo = _clone(balanceObj.e_money || initAccInfo);
  const point: IAccountInfo = _clone(balanceObj.point || initAccInfo);
  const ec: IAccountInfo = _clone(balanceObj.ec || initAccInfo);
  const stock: IAccountInfo = _clone(balanceObj.stock || initAccInfo);
  const kantan: IAccountInfo = _clone(balanceObj.kantan || initAccInfo);

  bank.accounts = _filter(bank.accounts, (item: IAccount) => !item.is_hide);
  credit.accounts = _filter(credit.accounts, (item: IAccount) => !item.is_hide);
  e_money.accounts = _filter(e_money.accounts, (item: IAccount) => !item.is_hide);
  point.accounts = _filter(point.accounts, (item: IAccount) => detectPointAccounts(item));
  ec.accounts = _filter(ec.accounts, (item: IAccount) => !item.is_hide);
  stock.accounts = _filter(stock.accounts, (item: IAccount) => !item.is_hide);
  kantan.accounts = _filter(kantan.accounts, (item: IAccount) => !item.is_hide);

  return { bank, credit, e_money, point, ec, stock, kantan };
}

function detectPointAccounts(item: IAccount) {
  return AccountType.au_ponta_market === getAccountType(item.data_source)
    ? (!item.is_hide && +item.balance > 0)
    : !item.is_hide;
}

function getUpdatedTransactionsChartDetail(
  transactionsChartValues: ITransactionChartItem[],
  datetime: string,
  activeChartData: number
) {
  const index = transactionsChartValues.findIndex(transactionChartValue => transactionChartValue.time === datetime);
  if (index === -1) {
    return transactionsChartValues;
  }
  const transactionsChartValuesCopy = [...transactionsChartValues];
  transactionsChartValuesCopy[index] = { ...transactionsChartValuesCopy[index], value: activeChartData };
  return transactionsChartValuesCopy;
}

function convertInsuranceData(data: IAuInsuranceResponseItem[] | null) {
  return data
    ? data.map((item: IAuInsuranceResponseItem) => {
      return {
        agreeStatus: item.agree_status,
        bankGoodsCd: item.bank_goods_cd,
        contractDate: item.contract_date,
        contractEndDate: item.contract_end_date,
        corpCd: item.corp_cd,
        displayNum: item.display_num,
        payInsurance: item.pay_insurance,
        paymentMethod: item.payment_method
      };
    })
    : []
  ;
}
